import { PlusCircleIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from '../hooks/useStore';
import { togglePanel } from '../redux/slices/createStreamSlice';
import { IStreamListingItem } from '../types/Database';
import StreamItem from './StreamItem';

export default function StreamList({
  streams,
  withStatus = false,
}: {
  streams: IStreamListingItem[];
  withStatus?: boolean;
}) {
  const dispatch = useAppDispatch();

  const openPopup = () => {
    dispatch(togglePanel({ open: true }));
  };

  if (!streams.length) {
    return (
      <div className="px-4 sm:px-6 lg:px-8 mt-4">
        <button
          type="button"
          className="relative block w-full rounded-lg border-2 border-dashed border-white p-12 text-center hover:border-gray-300 focus:outline-none flex flex-col items-center"
          onClick={openPopup}
        >
          <PlusCircleIcon className="text-white h-12 w-12" />
          <span className="mt-2 block text-sm font-medium text-white">Create your first stream</span>
        </button>
      </div>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="overflow-hidden sm:-mx-6 md:mx-0">
        <table className="min-w-full border-separate border-spacing-x-0 border-spacing-y-4">
          <tbody className="">
            {streams.map((stream: IStreamListingItem) => (
              <StreamItem key={stream.id} stream={stream} withStatus={withStatus} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
