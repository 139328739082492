import { useAuth } from '@elrond-giants/erd-react-hooks';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { NextPage } from 'next';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import RequiresAuth from '../components/RequiresAuth';
import HomeLayout from '../components/shared/HomeLayout';
import Layout from '../components/shared/Layout';
import StreamList from '../components/StreamList';
import { appName } from '../config';
import { useAppDispatch } from '../hooks/useStore';
import { togglePanel } from '../redux/slices/createStreamSlice';
import { addStream, fetchStreams, removeStream } from '../redux/slices/streamListSlice';
import { RootState } from '../redux/store';
import { IStreamResource } from '../types/Database';
import { bech32ToHex } from '../utils/presentation';
import { finishedStreamsPath } from '../utils/routes';
import { getTableName, mapStreamResponse, STREAMS_TABLE, supabase } from '../utils/supabase';

const Home: NextPage = () => {
  const { address } = useAuth();
  const dispatch = useAppDispatch();
  const streamList = useSelector((state: RootState) => state.streams.items);

  const openPopup = () => {
    dispatch(togglePanel({ open: true }));
  };

  useEffect(() => {
    if (!address) return;

    dispatch(fetchStreams({ address }));

    const channel = supabase
      .channel("db-messages")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: getTableName(STREAMS_TABLE),
          filter: `sender=eq.${bech32ToHex(address)}`,
        },
        (payload) => {
          const mappedRow = mapStreamResponse(payload.new as IStreamResource);
          // @ts-ignore
          dispatch(addStream(mappedRow));
        }
      )
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: getTableName(STREAMS_TABLE),
          filter: `recipient=eq.${bech32ToHex(address)}`,
        },
        (payload) => {
          const mappedRow = mapStreamResponse(payload.new as IStreamResource);
          // @ts-ignore
          dispatch(addStream(mappedRow));
        }
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: getTableName(STREAMS_TABLE),
          filter: `sender=eq.${bech32ToHex(address)}`,
        },
        (payload) => {
          if (payload.new.status !== "active") dispatch(removeStream(payload.new.id));
        }
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: getTableName(STREAMS_TABLE),
          filter: `recipient=eq.${bech32ToHex(address)}`,
        },
        (payload) => {
          if (payload.new.status !== "active") dispatch(removeStream(payload.new.id));
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [address]);

  return (
    <RequiresAuth>
      <Layout>
        <HomeLayout>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-light-blue text-3xl font-semibold">Dashboard</h1>
                <p className="text-white mt-1">
                  We know how important is to get paid on time. With {appName}, on time means every second.
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  onClick={openPopup}
                  className="bg-light-blue inline-flex items-center justify-center rounded-full text-secondary sm:w-auto hover:opacity-90 text-sm font-semibold py-3 px-6 uppercase shadow"
                >
                  Create Stream
                </button>
              </div>
            </div>
            <div className="flex justify-end text-white items-center text-sm font-medium mt-16">
              <ArchiveBoxIcon className="w-5 h-5 mr-2" />
              <Link href={finishedStreamsPath}>Finished streams</Link>
            </div>
          </div>
          <StreamList streams={streamList} />
        </HomeLayout>
      </Layout>
    </RequiresAuth>
  );
};

export default Home;
