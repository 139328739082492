export default function HomeLayout({ children }: any) {
  return (
    <div className="bg-primary">
      <img
        src="/icon.svg"
        alt="CoinDrip Icon"
        className="fixed h-[100vh] sm:h-[120vh] top-0 sm:top-[-10vh] mx-auto left-0 right-0 opacity-5 object-cover"
      />
      <div className="mx-auto max-w-6xl relative z-10">
        <div className="relative z-10">{children}</div>
      </div>
    </div>
  );
}
