import { useAuth } from '@elrond-giants/erd-react-hooks/dist';
import Link from 'next/link';
import { useMemo } from 'react';
import Moment from 'react-moment';

import { IStreamListingItem } from '../types/Database';
import { classNames, getProgress, getShortAddress } from '../utils/presentation';
import { streamPath } from '../utils/routes';
import ProgressBar from './ProgressBar';

export default function StreamItem({ stream, withStatus }: { stream: IStreamListingItem; withStatus: boolean }) {
  const { address } = useAuth();
  const shortAddress = useMemo(() => {
    if (!stream) return "";
    if (address === stream?.recipient) {
      return getShortAddress(stream.sender);
    }
    return getShortAddress(stream.recipient);
  }, [stream, address]);

  const progress = useMemo(() => {
    const progress = getProgress(stream);
    return Math.round(progress * 100);
  }, [stream]);

  const label = useMemo(() => {
    if (!stream) return "";
    if (address === stream?.recipient) {
      return "IN";
    }
    return "OUT";
  }, [stream]);

  const statusColor = useMemo(() => {
    switch (stream.status) {
      case "cancelled":
        return "text-red-400";
      case "finalized":
        return "text-green-400";
      default:
        return "text-white";
    }
  }, [stream.status]);

  return (
    <Link key={stream.id} href={streamPath(stream.id)}>
      <tr className="cursor-pointer hover:bg-opacity-90 bg-[#1C53CC]">
        <td className="text-sm text-white sm:w-auto sm:max-w-none rounded-l-full py-5 pl-10 pr-4">
          <div className="text-secondary text-xs uppercase font-semibold hidden sm:block">address</div>
          <span className="text-secondary font-semibold sm:text-white sm:font-normal pr-1">
            {label === "IN" ? "From " : "To "}
          </span>
          {shortAddress}
          <dl className="font-normal lg:hidden text-sm">
            <dt className="sr-only">Start date</dt>
            <dd className="mt-1 truncate text-white">
              <span className="text-secondary font-semibold pr-1">Start</span>{" "}
              <Moment fromNow>{stream.start_time}</Moment>
            </dd>
            <dt className="sr-only sm:hidden">End date</dt>
            <dd className="mt-1 truncate text-white sm:hidden">
              <span className="text-secondary font-semibold pr-1">End</span> <Moment fromNow>{stream.end_time}</Moment>
            </dd>
          </dl>
        </td>
        <td className="text-sm text-white lg:table-cell rounded-r-full sm:rounded-r-none py-5 pl-4 pr-10 sm:pr-4">
          <div className="text-secondary text-xs uppercase font-semibold">value</div>
          <div className="flex sm:items-center sm:space-x-4 flex-col sm:flex-row">
            <span className="flex-shrink-0">
              {stream.payment_amount} {stream.payment_token_label}
            </span>
            <span
              className={classNames(
                label === "IN" ? " text-green-400" : " text-red-400",
                "inline-flex items-center font-semibold text-xs"
              )}
            >
              <svg className="-ml-0.5 mr-1 h-2 w-2" fill="currentColor" viewBox="0 0 8 8">
                <circle cx={4} cy={4} r={3} />
              </svg>
              {label}
            </span>
          </div>
        </td>
        <td className="hidden text-sm text-white sm:table-cell py-5 px-4">
          <div className={classNames("text-secondary text-xs uppercase font-semibold", withStatus ? "" : "mb-1.5")}>
            {withStatus ? "status" : "progress"}
          </div>
          {withStatus ? (
            <span className={classNames("capitalize", statusColor)}>{stream.status}</span>
          ) : (
            <ProgressBar value={progress} />
          )}
        </td>
        <td className="hidden py-5 text-sm text-white sm:table-cell px-4">
          <div className="text-secondary text-xs uppercase font-semibold">start date</div>
          <Moment fromNow>{stream.start_time}</Moment>
        </td>
        <td className="hidden py-5 pl-4 pr-10 text-sm text-white sm:table-cell rounded-r-full">
          <div className="text-secondary text-xs uppercase font-semibold">end date</div>
          <Moment fromNow>{stream.end_time}</Moment>
        </td>
      </tr>
    </Link>
  );
}
